<template>
  <div class="product">
    <!-- Pour mettre les derniers produits en premier utiliser .slice().reverse() -->
    <ProductItem :products="products.slice().reverse()" />
  </div>
</template>

<script>
import ProductItem from "@/components/ProductItem.vue";

export default {
  name: "Home",
  components: {
    ProductItem,
  },
  data: function () {
    return {
      products: [
        {
          id: "0",
          title: "BAGUE « Aurore »",
          description:
            "Inspirée des matins doux de la vie, la bague aurore rappelle la douceur et la légèreté de nos quotidiens. À la lueur de ses couleurs pastels elle dessine la beauté de vos mains.",
          items: [
            {
              title: "Bague « Aurore »",
              composants: [
                "Or jaune 18k",
                "Perles d’eau douce blanches/ rose nacré",
                "3,3 grm",
              ],
              price: "635",
            },
          ],
          images: [
            require("@/assets/IMG_1611.jpeg"),
            require("@/assets/IMG_0160.png"),
            require("@/assets/IMG_1454.jpeg"),
          ],
        },
        {
          id: "1",
          title: "BOUCLES D’OREILLES « Iris »",
          description:
            "Une douceur de couleur allie asymétrie et originalité. Les boucles d’oreilles « Iris » incarnent à la fois pleine l’irrégularité de la perfection. Les boucles d’oreilles « Iris » peuvent d’autant plus se marier parfaitement avec la bague « Aurore » au gré de vos envies.",
          items: [
            {
              title: "Boucles d’oreilles « Iris »",
              composants: [
                "Or jaune 18k / 14k",
                "Perles d’eau douce blanches / rose nacré",
                "4,12 grm",
              ],
              price: "524",
            },
          ],
          images: [
            require("@/assets/275905220_510507540709095_8927767100294967834_n.jpg"),
            require("@/assets/276953945_278054341183944_1097090401467589920_n.jpg"),
            require("@/assets/275895058_1076111436267702_802914556491312965_n.jpg"),
          ],
        },
        {
          id: "2",
          title: "« Mes rêveries perlées »",
          description:
            "La collection « Mes rêveries perlées » s’accompagne du collier « Théia » perlées fermé d’un délicat fermoir argenté, et d’une paire de boucles d’oreilles « Les marguerites ». Cette collection rappel la douceur du soleil estival et la joie perlée de la vie.",
          items: [
            {
              title: "Collier « Théia »",
              composants: [
                "Perles d’eau douce",
                "Fermoire en argent entourée de rhodium",
              ],
              price: "125",
            },
            {
              title: "Boucles d’oreilles «  Les Marguerites »",
              composants: ["Perles d’eau douce", "Monture or jaune 10K"],
              price: "100",
            },
          ],
          images: [
            require("@/assets/357990417_282645487610815_6196266979035698699_n.jpg"),
            require("@/assets/357720955_612140514344668_2302208852196724816_n.jpg"),
            require("@/assets/354672084_226467776831878_8370725508091284028_n.jpg"),
          ],
        },
      ],
    };
  },
};
</script>
