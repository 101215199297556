<template>
  <div
    class="product-item"
    v-for="(product, index) in products"
    :key="product.id"
  >
    <h2 class="product-item-title">{{ product.title }}</h2>
    <p class="product-item-description">
      {{ product.description }}
    </p>
    <div class="product-item-composants">
      <h3 class="product-item-composants-title"><em>composants:</em></h3>
      <div
        v-for="(item, i) in products[index].items"
        :key="i"
        class="product-item-composants-item"
      >
        <p class="product-item-composants-item-title">{{ item.title }} :</p>
        <ul class="product-item-composants-list">
          <li
            class="product-item-composants-list-item"
            v-for="(composant, j) in item.composants"
            :key="j"
          >
            {{ composant }}
          </li>
        </ul>
        <p class="product-item-price">{{ item.price }}$CAN + tax.</p>
      </div>
    </div>
    <div class="product-item-images">
      <div class="product-item-images-row">
        <div
          class="product-item-images-row-item"
          v-for="(image, j) in products[index].images"
          :key="j"
        >
          <img v-bind:src="image" alt="Image" width="" height="" />
        </div>
      </div>
    </div>
    <router-link to="/contact" title="Contact" class="btn-link">
      Commander
    </router-link>
  </div>
</template>

<script>
export default {
  name: "Home",
  components: {},
  props: {
    products: Array,
  },
};
</script>
